export enum PagesFilterTypes {
  LOT = 'lot',
  ARCHITECTURAL = 'architectural',
  PLANS = 'plans',
  PARCEL = 'parcel',
  LOT_SALES = 'lot_sales',
  ELEVATIONS = 'elevations',
  MISC_SUBMITTALS = 'misc_submittals',
  CONTRACTS = 'contracts',
}
