import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import BaseState from '~/models/state/baseState'
import { PagesFilterTypes } from '~/enums/pagesFilters'

@Module({
  name: 'persisted-filters',
  stateFactory: true,
  namespaced: true,
})
export default class PersistedFilters extends VuexModule implements BaseState {
  filters: any = {}

  get getLotFilters(): any {
    return this.filters?.[PagesFilterTypes.LOT]
  }

  get getArchFilters(): any {
    return this.filters?.[PagesFilterTypes.ARCHITECTURAL]
  }

  get getPlansFilters(): any {
    return this.filters?.[PagesFilterTypes.PLANS]
  }

  get getElevationFilters(): any {
    return this.filters?.[PagesFilterTypes.ELEVATIONS]
  }

  get getParcelFilters(): any {
    return this.filters?.[PagesFilterTypes.PARCEL]
  }

  get getLotSalesFilters(): any {
    return this.filters?.[PagesFilterTypes.LOT_SALES]
  }

  @Action({ rawError: true })
  setFilter(payload: { name: string; filter: any }) {
    this.setLotFilter(payload)
  }

  @Action({ rawError: true })
  clearFilter(payload: { name: string }) {
    this.clearLotFilter(payload.name)
  }

  @Mutation
  setLotFilter(payload: any): void {
    const { name, filter } = payload
    this.filters = { ...this.filters, [name]: filter }
  }

  @Mutation
  clearLotFilter(name?: string): void {
    if (name) {
      this.filters = { ...this.filters, [name]: {} }
    } else {
      this.filters = {}
    }
  }

  @Mutation
  clear(): void {
    this.filters = null
  }
}
