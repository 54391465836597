import { prop } from '@rxweb/reactive-forms'
import FormControl from './FormControl'
import { InputType } from '~/enums/inputType'
import { LabelSizes } from '~/enums/labelSizes'
import { ReportTypes } from '~/enums/reports'

const endDate: FormControl =  {
  id: 'end_date',
  label: 'Select End Date',
  type: InputType.date,
  parentClass: 'w-full mb-2',
  required: true,
  settings: {
    description: '',
    options: [],
    titleSize: LabelSizes.extraLarge,
  },
}

const HOME_SALES_CANCELLATIONS: Array<FormControl> = [
  endDate
]

const QMI: Array<FormControl> = [
  {
    id: 'start_date',
    label: 'Select Start Date',
    type: InputType.date,
    parentClass: 'w-full mb-2',
    required: true,
    settings: {
      description: '',
      options: [],
      titleSize: LabelSizes.extraLarge,
    },
  },
  endDate
]

export default class CustomReport {
  @prop() end_date: string
  @prop() start_date: string

  static getControls(report_id: string | number): Array<FormControl> {
    switch (report_id) {
      case ReportTypes.HOME_SALES_CANCELLATIONS:
        return HOME_SALES_CANCELLATIONS
      case ReportTypes.QMI:
        return QMI
      default:
        return []
    }
  }
}
