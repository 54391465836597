





































import { Component, Emit, Prop } from 'nuxt-property-decorator'
import BaseInputComponent from './BaseInput.vue'
import TitleInput from './TitleInput.vue'

@Component({
  components: {
    TitleInput,
  },
})
export default class SelectInput extends BaseInputComponent {
  @Prop({ default: (): any[] => [] }) options: any[]
  @Prop({ default: (): any => null }) defaultValue: any
  @Prop({ default: (): any[] => [] }) defaultValues: any[]
  @Prop({ default: 'Choose here' }) defaultLabel: string
  @Prop({ default: false }) isMultiSelect: boolean
  @Prop({ default: false }) disabled: boolean

  optionsSelected: number[] | string[] = []

  updated() {
    if (this.value === null) {
      // @ts-ignore
      this.$refs.selectOptions.selectedIndex = null
    }
  }

  @Emit('input')
  handleInput(event: any): number[] | string[] {
    const currentValue = event.target.value
    if (!this.isMultiSelect) {
      return currentValue
    }

    event.target.selected = !event.target.selected

    const selectOptions: any = this.$refs.selectOptions
    const valuesSelected: number[] | string[] = [...selectOptions.options]
      .filter((option: any) => option.selected)
      .map((option) => option.value)

    return valuesSelected
  }

  @Emit('validateField')
  handleBlur(): any {}

  isDisabledOption(option: any): boolean {
    if (!this.isMultiSelect) {
      return false
    }

    return option.hasOwnProperty('enabled') ? !option.enabled : false
  }

  optionClicked(event: any): boolean {
    if (!this.isMultiSelect) {
      return true
    }

    if (event.target.disabled) {
      return false
    }

    this.handleInput(event)
    event.preventDefault()
    return false
  }

  shouldSelect(option: any): boolean {
    if (!this.isMultiSelect) {
      return option.id ? option.id === this.value : option.value === this.value
    }

    if (this.isMultiSelect && this.options.length > 0 && option.selected !== undefined) {
      return option.selected
    }
    
    return this.defaultValues.includes(option.id)
  }
}
