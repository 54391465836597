





























import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component({})
export default class TitleInput extends Vue {
  @Prop({ default: 'text-base' })
  titleSize: string

  @Prop({ default: 'text-sm' })
  subTitleSize: string

  @Prop({ default: '' })
  title: string

  @Prop({ default: '' })
  subTitle: string

  @Prop({ default: false })
  required: boolean
}
