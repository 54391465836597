





















































import { Component, Vue, Watch, Emit } from 'nuxt-property-decorator'
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-forms'
import { mapGetters } from 'vuex'
import Reports from './classes/reports'
import Input from '~/components/shared/inputs/Input.vue'
import { InputType } from '~/enums/inputType'
import FormControl from '~/models/forms/FormControl'
import { NormalTrafficForm } from '~/models/forms'
import { FilterFields, NormalReport, Project, Notification } from '~/models'
import { LabelSizes } from '~/enums/labelSizes'
import { uiStore } from '~/store'
import { TrafficReportTypes, NotificationTypes } from '~/enums/trafficReport'
import { isValidSubmissionDate } from '~/utils/utils'
import { getFilters } from '~/api/filters.api'

type Settings = {
  description: string
  titleSize: string
  disabled: true
}

@Component({
  components: {
    Input,
  },
  computed: {
    ...mapGetters('user', {
      project: 'project',
      builderName: 'builderName',
    }),
  },
})
export default class NormalTrafficReport extends Vue {
  // variables from store
  project: Project
  builderName: string

  // types
  inputType: typeof InputType = InputType

  // local variables
  totalVisits: number = 0
  report: NormalReport = null
  lotSizes: Array<FilterFields> = []
  totalVisitsSettings: Settings = {
    description:
      'Total number of new, return, and realtor visits for the selected week.',
    titleSize: LabelSizes.extraLarge,
    disabled: true,
  }

  /**
   * Reactive forms
   */
  formGroup: IFormGroup<NormalTrafficForm> = null
  controls: Array<FormControl> = NormalTrafficForm.CONTROLS

  created(): void {
    this.setForm()
  }

  get isLastWeekReportSubmissionsEnabled(): boolean {
    if (!this.project || !this.project.config) {
      return true
    }
    return this.project.config.enable_lastweek_report_submission
  }

  @Watch('formGroup.controls.visits.value')
  @Watch('formGroup.controls.return_visits.value')
  @Watch('formGroup.controls.realtor_visits.value')
  onNormalFormChanged(): void {
    this.setTotalVisits()
  }

  @Watch('formGroup.controls.week_range.value', { deep: true })
  onWeekDateRangeChanged(
    newDatesRange: Array<string>,
    oldDatesRange: Array<string>
  ): void {
    this.notify([])
    if (!newDatesRange || !newDatesRange.length) {
      return
    }

    if (!oldDatesRange || !oldDatesRange.length) {
      this.getReport()
      return
    }

    if (newDatesRange[0] === oldDatesRange[0]) {
      return
    }
    this.getReport()
  }

  @Watch('formGroup.controls.size.value', { deep: true })
  onLotSizeChanged(newSize: string, oldSize: string): void {
    this.notify([])
    if (!oldSize) {
      return
    }

    if (parseInt(newSize, 10) === parseInt(oldSize, 10)) {
      return
    }

    this.getReport()
  }

  @Emit('onNotification')
  notify(notifications: Array<Notification>): Array<Notification> {
    return notifications
  }

  setTotalVisits(): void {
    const form: NormalReport = this.formGroup.value as NormalReport
    const visits: number = form.visits || 0
    const realtor_visits: number = form.realtor_visits || 0
    const return_visits: number = form.return_visits || 0

    this.totalVisits = visits + realtor_visits + return_visits
  }

  setForm(): void {
    this.formGroup = null

    const form: NormalTrafficForm = new NormalTrafficForm(
      this.report as NormalReport
    )

    if (!form) {
      return
    }

    this.setSizes()
    this.formGroup = new RxFormBuilder().formGroup(
      form
    ) as IFormGroup<NormalTrafficForm>
  }

  isFormValid(): boolean {
    if (!this.formGroup) {
      return false
    }
    return this.formGroup.valid
  }

  getErrors(): void {
    if (!this.formGroup.valid) {
      const allErrors: any = this.formGroup.getErrorSummary(true)
      this.notify(
        Object.values(allErrors).map((error: any) => ({
          message: error,
          type: NotificationTypes.ERROR,
        }))
      )
    }
  }

  async setSizes(): Promise<void> {
    this.lotSizes = await getFilters('lot_sizes', this.builderName)
    this.controls = this.controls.map((control: FormControl) => {
      if (control.id === 'size') {
        control.settings.options = this.lotSizes
      }
      return control
    })
  }

  async getReport(): Promise<void> {
    if (!this.isFormValid()) {
      // Setting this because the required fields in
      // advanced and normal forms are the same fields
      // we need to try to retrieve the report
      return
    }

    try {
      const data = this.formGroup.value
      this.report = (await Reports.retrieve(
        data.week_range[0],
        data.week_range[1],
        data.size
      )) as NormalReport
      this.setForm()
    } catch (error) {
      console.log(error)
    }
  }

  isAbleToSubmit(): boolean {
    if (!this.isFormValid()) {
      return false
    }

    let startDate: Date
    let endDate: Date
    if (this.report) {
      startDate = new Date(this.report.start_date)
      endDate = new Date(this.report.end_date)
    } else {
      const data = this.formGroup.value
      startDate = new Date(data.week_range[0])
      endDate = new Date(data.week_range[1])
    }

    return isValidSubmissionDate(
      startDate,
      endDate,
      this.isLastWeekReportSubmissionsEnabled
    )
  }

  async create(): Promise<void> {
    if (!this.isFormValid()) {
      this.getErrors()
      return
    }
    try {
      const data: any = this.formGroup.value
      await Reports.create(data, TrafficReportTypes.NORMAL, [])
      this.$toast.success('Submitted')
      uiStore.closeTrafficReport()
    } catch (error) {
      console.log(error)
    }
  }

  async update(): Promise<void> {
    if (!this.isFormValid()) {
      this.getErrors()
      return
    }

    try {
      const data: any = this.formGroup.value
      data.id = this.report.id
      await Reports.update(data, TrafficReportTypes.NORMAL, [])
      this.$toast.success('Updated')
      uiStore.closeTrafficReport()
    } catch (error) {
      console.log(error)
    }
  }

  cancel(): void {
    uiStore.closeTrafficReport()
  }
}
